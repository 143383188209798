import { useDisclosure } from '@chakra-ui/core'
import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../../../redux/selectors'
import { getStatusEscala } from '../../../api/incentives'
import StatusColor from './components/StatusColor'

function colorProgress(finalPercent, periodoFinalizado) {
  if (periodoFinalizado) {
    switch (true) {
      case finalPercent <= 99:
        return '#FD3838'
      case finalPercent >= 100:
        return '#7EFF92'
      default:
        return '#FD3838'
    }
  } else {
    switch (true) {
      case finalPercent <= 79:
        return '#FD3838'
      case finalPercent >= 80 && finalPercent <= 99:
        return '#FBFF3B'
      case finalPercent >= 100:
        return '#7EFF92'
      default:
        return '#7EFF92'
    }
  }
}

const ProbabilityCell = ({ filteredStatusEscala, fechaFin, boxProps }) => {
  const { t } = useTranslation('incentives')

  function statusProgress(finalPercent, periodoFinalizado, id) {
    if (periodoFinalizado) {
      switch (true) {
        case finalPercent <= 99:
          return t('incentives:notAchieved')
        case finalPercent >= 100:
          return t('incentives:achieved')
        default:
          return t('incentives:notAchieved')
      }
    } else {
      switch (true) {
        case finalPercent <= 79:
          return t('incentives:low')
        case finalPercent >= 80 && finalPercent <= 99:
          return t('incentives:medium')
        case finalPercent >= 100:
          return t('incentives:high')
        default:
          return t('incentives:high')
      }
    }
  }

  function statusInfo(pr, fechaFin, periodoFinalizado, id) {
    const today = new Date()
    const esTerminado = new Date(fechaFin) < today
    const proyeccionPorcentaje = pr * 100

    return esTerminado && proyeccionPorcentaje
      ? t('incentives:notAchieved')
      : statusProgress(proyeccionPorcentaje, periodoFinalizado, id)
  }

  return !!filteredStatusEscala ? (
    <StatusColor
      color={colorProgress(Math.trunc(filteredStatusEscala?.proyeccion * 100), filteredStatusEscala?.periodoFinalizado)}
      text={statusInfo(
        filteredStatusEscala.proyeccion,
        fechaFin,
        filteredStatusEscala.periodoFinalizado,
        filteredStatusEscala.escalaId,
      )}
      boxProps={boxProps}
    />
  ) : (
    ''
  )
}

export default ProbabilityCell
