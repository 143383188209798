import React from 'react'
import { getUserApplication, getUserApplicationId, getUserInff, getUserInformation } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { Button, Image, Tooltip } from '@chakra-ui/core'
import { postLoginNezasa, postRegistroLogin } from '../../../api/loginUnico'
import Autologin from '../../../static/img/auto_login.webp'
import { useTranslation } from 'react-i18next'

function AutoLogin2({ proveedorId, parametros }) {
  const userInfo = useSelector(getUserInformation)
  const appInfo = useSelector(getUserApplication)
  const appId = useSelector(getUserApplicationId)
  const { t } = useTranslation(['suppliers', 'common'])
  const today = new Date()

  //const token = document.head.querySelector('[name~=csrf-token][content]')?.content

  async function loginNezasa(email, password) {
    try {
      const url = 'https://go-api.tripyeah.com/backoffice/api/v1/auth/sign-in'
      const data = {
        email,
        password,
      }

      const res = await postLoginNezasa(url, data)
      if (res.data) {
        const token = res.data.auth.token
        window.open(`https://go.tripyeah.com/auth?token=${token}&email=${email}`, '_blank')
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function loginLleego(email, password) {
    const url = `https://app.lleego.com/auto-login?e=${email}&w=${password}`
    try {
      window.open(url, '_blank')
    } catch (error) {
      console.log(error)
    }
  }

  /* async function loginViewtravel(username, password) {
    try {
      const url = 'https://view-travel.com/automatic-start/'
      const data = {
        username,
        password,
      }

      axios({
        method: 'POST',
        url: 'https://view-travel.com/automatic-start/',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET',
          'Access-Control-Allow-Headers': 'application/json',
        },
        data: data,
      })
        .then(function (response) {
          window.location.replace('https://view-travel.com')
        })
        .catch(function (error) {
          console.log('You cannot authenticate because you have an insecure domain')
        })
    } catch (error) {
      console.log(error)
    }
  } */

  const sideCardButtonStyles = last => {
    const styles = {
      marginBottom: last ? 'unset' : '0.5rem',
      width: '3rem',
      height: '3rem',
    }
    return styles
  }

  async function createRegistroLogin(data) {
    try {
      await postRegistroLogin(data)
    } catch (error) {
      console.log(error)
    }
  }

  function searchButton(p) {
    const param1 = p.proveedorLoginUnico?.url?.split('+')[0]
    const param2 = p.proveedorLoginUnico?.url?.split('+')[1]
    const param3 = p.proveedorLoginUnico?.url?.split('+')[2]
    const param4 = p.proveedorLoginUnico?.url?.split('+')[4]
    const param1value = p.valor?.split('+')[0]
    const param2value = p.valor?.split('+')[1]
    const param3value = p.valor?.split('+')[2]
    const param4value = p.valor?.split('+')[3]

    const url = p.proveedorLoginUnico?.proveedor?.web

    if (p.usuarioId === userInfo.id) {
      if (
        p.proveedorLoginUnico?.proveedor?.id === proveedorId &&
        !p.proveedorLoginUnico?.proveedor?.nombre.toLowerCase().includes('nezasa') &&
        !p.proveedorLoginUnico?.proveedor?.nombre.toLowerCase().includes('lleego') &&
        !p.proveedorLoginUnico?.proveedor?.nombre.toLowerCase().includes('gea multitrip')
      ) {
        return (
          <form
            target='_blank'
            action={url}
            key={p.id}
            method={p.proveedorLoginUnico?.proveedor?.nombre.toLowerCase().includes('vietur') ? 'get' : 'post'}
          >
            <Tooltip label={t('suppliers:singleLogin')}>
              <Button
                borderColor='#24428E'
                color='#24428E'
                variantColor='blue'
                w='full'
                variant='outline'
                key={p.id}
                type='submit'
                style={sideCardButtonStyles(true)}
                onClick={() => {
                  const data = {
                    fecha: today,
                    usuarioId: userInfo.id,
                    nombreUsuario: userInfo.username,
                    nombreAgencia: appInfo.nombre,
                    nombreProveedor: p.proveedorLoginUnico?.proveedor?.nombre,
                    proveedorId: p.proveedorLoginUnico?.proveedor.id,
                    agenciaId: appId,
                  }
                  createRegistroLogin(data)
                }}
              >
                <Image
                  style={{
                    maxWidth: 'unset',
                    width: '2rem',
                    filter: 'invert(18%) sepia(91%) saturate(1379%) hue-rotate(207deg) brightness(98%) contrast(96%)',
                  }}
                  src={Autologin}
                />
              </Button>
            </Tooltip>
            <input name={param1} id={param1} value={param1value} type='hidden' />
            <input name={param2} id={param2} value={param2value} type='hidden' />
            <input name={param3} id={param3} value={param3value} type='hidden' />
            <input name={param4} id={param4} value={param4value} type='hidden' />
          </form>
        )
      } else if (
        p?.proveedorLoginUnico?.proveedor?.id === proveedorId &&
        (p?.proveedorLoginUnico?.proveedor?.nombre?.toLowerCase().includes('nezasa') ||
        p?.proveedorLoginUnico?.proveedor?.nombre?.toLowerCase().includes('gea multitrip'))
      ) {
        return (
          <Tooltip label={t('suppliers:singleLogin')}>
            <Button
              borderColor='#24428E'
              color='#24428E'
              variantColor='blue'
              w='full'
              variant='outline'
              key={p.id}
              onClick={e => {
                e.preventDefault()
                loginNezasa(param1value, param2value)
                const data = {
                  fecha: today,
                  usuarioId: userInfo.id,
                  nombreUsuario: userInfo.username,
                  nombreAgencia: appInfo.nombre,
                  nombreProveedor: p.proveedorLoginUnico?.proveedor?.nombre,
                  proveedorId: p.proveedorLoginUnico?.proveedor.id,
                  agenciaId: appId,
                }
                createRegistroLogin(data)
              }}
              style={sideCardButtonStyles(true)}
            >
              <Image
                style={{
                  maxWidth: 'unset',
                  width: '2rem',
                  filter: 'invert(18%) sepia(91%) saturate(1379%) hue-rotate(207deg) brightness(98%) contrast(96%)',
                }}
                src={Autologin}
              />
            </Button>
          </Tooltip>
        )
      } else if (
        p.proveedorLoginUnico?.proveedor?.id === proveedorId &&
        p.proveedorLoginUnico?.proveedor?.nombre?.toLowerCase().includes('lleego')
      ) {
        return (
          <Tooltip label={t('suppliers:singleLogin')}>
            <Button
              borderColor='#24428E'
              color='#24428E'
              variantColor='blue'
              w='full'
              variant='outline'
              key={p.id}
              onClick={e => {
                e.preventDefault()
                loginLleego(param1value, param2value)
                const data = {
                  fecha: today,
                  usuarioId: userInfo.id,
                  nombreUsuario: userInfo.username,
                  nombreAgencia: appInfo.nombre,
                  nombreProveedor: p.proveedorLoginUnico?.proveedor?.nombre,
                  proveedorId: p.proveedorLoginUnico?.proveedor.id,
                  agenciaId: appId,
                }
                createRegistroLogin(data)
              }}
              style={sideCardButtonStyles(true)}
            >
              <Image
                style={{
                  maxWidth: 'unset',
                  width: '2rem',
                  filter: 'invert(18%) sepia(91%) saturate(1379%) hue-rotate(207deg) brightness(98%) contrast(96%)',
                }}
                src={Autologin}
              />
            </Button>
          </Tooltip>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }

  return <>{parametros === false ? null : parametros?.map(p => searchButton(p))}</>
}

export default AutoLogin2
